:root {
  --access-code-input-line-height: 2.2;
  --accommodations-table-container-max-width: 1290px;
  --add-join-class-modal-actions-btn-font-weight: bold;
  --add-join-class-modal-actions-height: auto;
  --add-join-class-modal-header-height: auto;
  --assignment-active-tab-nav-background-color: #ffb42c;
  --assignment-active-tab-nav-color: #303133;
  --assignment-active-tab-nav-hover-color: #ffba3c;
  --assignment-tab-nav-background-color: #f3f3f3;
  --assignment-tab-nav-color: #626262;
  --assignment-tab-nav-hover-color: #fafafa;
  --border-radius: 4px;
  --breadcrumb-chevron-font-size: 12px;
  --breadcrumb-chevron-font-weight: 900;
  --breadcrumb-chevron-foreground-color: var(--breadcrumb-foreground-color);
  --breadcrumb-chevron-opacity: 1;
  --breadcrumb-foreground-color-clickable: #fff;
  --breadcrumb-foreground-color: #fff;
  --breadcrumb-wrapper-color: transparent;
  --breadcrumb-wrapper-padding: 18px 40px 5px;
  --card-background-color: #767676;
  --card-border-color: #dadadb;
  --card-content-background-color: #fff;
  --card-default-lesson-color: #707071;
  --card-tag1-background-color: #6435c9;
  --card-tag2-background-color: #21ba45;
  --color-key-card-admin-classrooms-iconKeys-square-padding: 2px 0 0 0;
  --course-tree-branch-card-image-wrapper-margin: 6px;
  --course-tree-leaf-card-image-wrapper-margin: 6px;
  --demolink-launcher-box-button-height: auto;
  --filter-card-label-font-size: 14px;
  --gradebook-detail-colorkey-font-size: 14px;
  --gradebook-export-button-bg-color-hover: #ffba3c;
  --gradebook-export-button-bg-color: #ffb42c;
  --gradebook-export-button-border-color: #ffb42c;
  --gradebook-export-button-color: #453536;
  --modal-title-text-color: #000;
  --nav-separator-height: 10px;
  --report-default-approaching: #e3ad4b;
  --report-default-developing: #0091c6;
  --report-default-meeting: #80bd44;
  --report-default-not-enough-data: #767676;
  --report-download-button-bg-color-hover: #ffba3c;
  --report-download-button-bg-color: #ffb42c;
  --report-download-button-border-color: #ffb42c;
  --report-download-button-color: #453536;
  --student-assignment-card-button-bg-color: #2b5c00;
  --student-assignment-card-min-height: 115px; /* 127px; */
  --student-assignment-card-padding: 0; /* 0 0 0 4px; */
  --student-border-radius: 4px;
  --student-dashboard-assignment-card-min-height: 114px;
  --student-dashboard-assignment-card-padding: 0;
  --student-dashboard-widget-trophy-banner-font-size: 18px;
  --student-dashboard-widget-trophy-range-font-size: 16px;
  --student-primary-button-min-height: 26px;  
  --student-progress-display-summary-widget: none;
  --student-progress-display-trophy-column: none;
  --student-progress-header-color: #fff;
  --student-progress-widget-course-subtitle-font-size: 14px;
  --student-progress-widget-course-title-font-size: 14px;
  --student-progress-widget-top-label-font-size: 18px;
  --student-progress-widget-trophy-banner-font-size: 16px;
  --student-progress-widget-trophy-range-font-size: 14px;
  --theme-background-color-login: transparent;
  --theme-background-color: transparent;
  --theme-background-header-color: #fff;
  --theme-banner-color: #fff;
  --theme-basic-button-color: #d83e19;
  --theme-basic-button-hover-color: #e54721;
  --theme-border-color: #808284;
  --theme-bright-green: #3cb24a;
  --theme-bright-orange: #edab2f;
  --theme-bright-red: #cd373d;
  --theme-button-border-radius: 4px;
  --theme-dark-font-color: rgba(0, 0, 0, 0.87);
  --theme-element-color-login: #fff;
  --theme-element-color: #fff;
  --theme-font-color-directly-on-background: white;
  --theme-font-color: rgba(0, 0, 0, 0.87);
  --theme-font-family: 'Roboto', arial, sans-serif;
  --theme-font-size: 13px;
  --theme-header-color-login: #453536;
  --theme-header-color: rgba(0, 0, 0, 0.87);
  --theme-header-font-size-login: 22px;
  --theme-header-font-weight: bold;
  --theme-header-title-color: var(--theme-font-color-directly-on-background);
  --theme-light-green: #eef4e9;
  --theme-light-orange: #efe0be;
  --theme-light-red: #f0d3d4;
  --theme-link-color-directly-on-background: rgba(255, 255, 255, 0.87);
  --theme-link-color: #3a9cfa;
  --theme-primary-banner-button-bg-color: #fff;
  --theme-primary-banner-button-color: #d83e19;
  --theme-primary-banner-button-hover-color: #fafafa;
  --theme-primary-button-bg-color: #ec5421;
  --theme-primary-button-bg-disabled-color: #dadadb;
  --theme-primary-button-color: #fff;
  --theme-primary-button-hover-color: #ed6132;
  --theme-primary-button-min-height: 26px;
  --theme-primary-nav-button-bg-color: #d83e00;
  --theme-secondary-background-color: #eee;
  --theme-secondary-banner-color: #fff;
  --theme-secondary-border-color: #fdb017;
  --theme-secondary-button-bg-color: #d83e19;
  --theme-secondary-button-border-radius: 8px;
  --theme-secondary-button-color: #fff;
  --theme-secondary-button-hover-color: #e54721;
  --theme-secondary-font-color: #3a9cfa;
  --theme-secondary-font-size: 11px;
  --theme-secondary-header-font-weight: var(--theme-header-font-weight);
  --theme-secondary-nav-button-bg-color: #feb300;
  --theme-secondary-nav-button-color: #2a2b2d;
  --theme-secondary-top-nav-box-shadow: 0 0 10px 0 rgba(43, 134, 189, 0.5);
  --theme-secondary-top-nav-color: #2a2b2d;
  --theme-secondary-top-nav-selected-color: #2a2b2d;
  --theme-student-carousel: #dadadb;
  --theme-tertiary-banner-color: #feb300;
  --theme-tertiary-font-color: var(--theme-font-color);
  --theme-tertiary-font-size: 14px;
  --theme-top-nav-color: #d83e00;
  --theme-top-nav-selected-color: #fff;
  --top-nav-notification-bg-color: #d83e19;
  --top-nav-notification-color: white;
}

@import 'Buttons';
@import 'Fonts';

body.c2c-satellite.openupresources {
  height: 100vh !important;
  &[data-satellite-user-role='login'] {
    #root {
      background-color: var(--theme-background-color) !important;
      background-image: url('../img/openupresources-bg-login.webp') !important;
      background-size: cover !important;
    }
  }
  &[data-satellite-user-role='student_user'] {
    #root {
      background-color: var(--theme-background-color) !important;
      background-image: url('../img/openupresources-bg-student.webp') !important;
      background-size: cover !important;
    }
  }
  &:not([data-satellite-user-role='login'], [data-satellite-user-role='student_user']) {
    #root {
      background-color: var(--theme-background-color) !important;
      background-image: url('../img/openupresources-bg.webp') !important;  
      background-size: cover !important;
    }
  }

  #root > .verification-page, .main-view {
    &.student-client {
      --breadcrumb-foreground-color: #eee;
      --breadcrumb-wrapper-padding: 10px 40px;
      --nav-separator-height: 0px;
      --theme-secondary-border-color: #feb300;
    }
  }

  font-family: var(--theme-font-family);
  font-size: 13px;
  font-weight: normal;

  .use-react-wrapper {
    display: none;
  }

  .admin-dashboard-welcome-details-wrapper {
    color: var(--theme-font-color-directly-on-background) !important;
  }
  .teacher-present-button {
    display: none;
  }
  .ui.image.logo {
    width: 56px;
  }
  .ui.cards .ui.segment {
    padding: 5px;
    box-shadow: none;
  }
  
  .ui.container.class-course-container .ui.segment.course-card-satcore {
    padding: 0px !important;
  }

  .class-settings-view .ui.container.settings-image .image-wrapper .default-class-image {
    background-color: var(--card-background-color);
  }
  .bread-crumb-wrapper {
    background-color: var(--breadcrumb-wrapper-color) !important;
    display: block;
    // padding: 10px 40px;
    padding: var(--breadcrumb-wrapper-padding) !important;
  }
  .ui.large.breadcrumb, .ui.breadcrumb {
    color: var(--breadcrumb-foreground-color) !important;
    font-size: var(--theme-font-size) !important;
    font-weight: 600 !important;
    margin-top: 6px;
    a {
      color: var(--breadcrumb-foreground-color-clickable) !important;
      font-size: var(--theme-font-size) !important;
    }
    i {
      color: var(--breadcrumb-chevron-foreground-color) !important;
      font-size: var(--breadcrumb-chevron-font-size) !important;
      font-weight: var(--breadcrumb-chevron-font-weight) !important;
      opacity: var(--breadcrumb-chevron-opacity) !important;
    }
  }
  /* if there is no background, the bread crumb text color needs to be dark, not white */
  .no-background {
    .bread-crumb-wrapper, .class-header, .ui.breadcrumb, .ui.large.breadcrumb {
      a {
        color: var(--theme-font-color) !important;
      }
      .divider {
        color: var(--theme-font-color) !important;
      }
      .active.section {
        color: var(--theme-font-color) !important;
      }
    }
  }
  /* Checkboxes */
  .sc-check {
    .check-label {
      font-size: 12px;
    }
    .checkmark {
      border-color: #707070;
    }
    .checkmark:after {
      font-weight: bold;
    }
  }
  .sc-radio .radio-label {
    font-size: 12px;
    .radio-button {
      border-color: #959595;
    }
  }
  /* Icons background */
   .resource-pacing-toolbar-container .toolbar-item-trigger {
    img {
      filter: var(--theme-icon-color);
    }
    &.manual-scoring img {
      filter: inherit;
    }
  }
  .resource-pacing-modal-container .modal-section .modal-section-content {
    .resource-duration .resource-duration-input-wrapper .resource-duration-img-wrapper img,
    .resource-option.teacher-assign img,
    .resource-option.visible-to-students .resource-option-img-wrapper img {
      filter: var(--theme-icon-color); 
    }
  }

  /* Access codes */
  .accessCode {
    .accessCodeRefresh {
      margin-top: .3em;
    }
    .accessCodeLabel {
      // placeholder
    }
  }

  /* Pagination */
  .ui.menu {
    box-shadow: none;
  }
  .ui.pagination.menu {
    .active.item {
      background-color: #dadadb;
    }
    .item {
      border-left: solid 1px #e2e2e2;
    }
  }
  
  /* Add course */
  .class-header .overlay {
    display: none;
  }

  .course-tree-leaf-card-nav-buttons .ui.primary.button,
  .course-tree-leaf-card-nav-buttons .ui.basic.primary.button {
    font-size: var(--theme-secondary-font-size) !important;
  }

  .student-client {
    overflow: hidden;
    .no-assignments-wrapper {
      width: 100%;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .no-assignments-img {
        width: 154px;
        height: 187px;
        background-image: url(../img/graphic-zero-state.svg);
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 auto;
      }

      .no-assignments-message {
        color: var(--theme-font-color);
        font-family: var(--theme-font-family) !important;
      }
    }

    // TODO remove
    // .ui.segment.item.student-assignment-card {
    //   // placeholder
    // }
    /* end .student-client */
  }
  @media only screen and (max-width: 765px) {
    .ui.segment.item.student-assignment-card {
      min-width: 650px !important;
      max-width: 650px !important;
      min-height: 310px !important;
      max-height: auto !important;
      .image {
        height: auto;
        max-height: 175px;
        img {
          border-radius: 0 !important;
          width: auto;
          height: auto;
          max-height: 175px;
        }
      }
    }
  }

  .ui.popup {
    &.custom-popup {
      font-size: var(--theme-font-size);
    }
    .popup-text-wrapper {
      .card-list-text {
        display: inline;
        margin: 8px 0 0 2px;

        &.blue {
          color: var(--theme-secondary-font-color);
        }

        &.clickable {
          cursor: pointer;
        }
      }
    }

    &.gradebook-assignment-column-popup {
      div div .button {
        width: 118px;
        height: 30px;
      }
    }

    &.gradebook-summary-table-popup {
      .centered-popup-box {
        div .button {
          width: 88px !important;
        }
      }
    }
    &.gradebook-hourglass-popup {
      height: fit-content !important;
    }
  }

  .ckeditor {
    border-radius: var(--border-radius);
    box-shadow: none;
    border: 1px solid var(--card-border-color);
  }
  .ui.segment.list-card {
    background: var(--card-content-background-color);
    margin: 1rem auto;
    width: 100%;
    max-width: 1127px;
    border-radius: var(--border-radius);
    box-shadow: none;
    border: 1px solid var(--card-border-color);

    // TODO remove
    // .course-view-button, .popup-button {
    //   .button-primary-basic();
    // }

    .button {
      min-width: 80px !important;
    }
    .leaf-card-image-wrapper {
      background-color: #0072bc;
    }
  }

  .toggle-hide-or-show-wrapper {
    .ui.toggle.checkbox input:checked ~ label:before {
      font-size: 11.5px;
      padding: 2px 0 0 9px;
    }
    .ui.toggle.checkbox label:before {
      font-size: 11px;
      padding: 2px 0 0 23px;
    }

    .ui.toggle.checkbox {
      &.hide-or-show-toggler-directly-on-background {
        label:before {
          background-color: var(--theme-primary-button-bg-disabled-color);
        }
        input:checked ~ label:before {
          // placeholder
        }
      }
      label:before {
        font-size: 11px;
        padding: 2px 0 0 23px;
      }
    }
  }
  .ui.toggle.checkbox.cell-grades-toggler label:before {
    padding: 2.75px 0 0 16px !important;
  }
  .ui.toggle.checkbox.cell-grades-toggler input:checked ~ label:before {
    padding: 2.25px 13px 0 0 !important;
  }

  @media only screen and (min-width: 1200px) {
    .ui.container.class-course-container,
    .ui.container.class-course-list-container,
    .ui.container.class-content {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  @media only screen and (min-width: 1200px) {
    .ui.container.dashboard-container {
      width: calc(100% - 20px);
      max-width: 1127px !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  .ui.container.class-course-container .ui.segment.course-card-satcore {
    .course-card-image-wrapper {
      padding: 5px;
      .course-card-image {
        background-color: var(--card-background-color) !important;
        max-height: 104px;
        max-width: 157px !important;
      }
    }
  }
  .standards-list-container .name-pill {
    margin: 2.5px;
  }
  /* Buttons */
  .ui.segment.item.assignment-card .ui.primary.button {
    // padding-top: 3px !important;
    box-shadow: none !important;
    font-size: var(--theme-secondary-font-size) !important;
    // min-height: var(--theme-primary-button-min-height) !important;
    min-width: 85px !important;
    padding-top: 1px !important;
  }

  .course-card-nav-buttons {
    .button, button {
      &:not(.disabled, .pseudo-disabled) {
        background-color: var(--course-card-primary-button-bg-color) !important;
        color: var(--course-card-primary-button-color) !important;
        &:not(.basic):hover {
          background-color: var(--course-card-primary-button-hover-color) !important;
        }
        &.basic {
          border-color: var(--course-card-basic-button-color) !important;
          color: var(--course-card-basic-button-color) !important;
          &:hover {
            border-color: var(--course-card-basic-button-hover-color) !important;
            color: var(--course-card-basic-button-hover-color) !important;
          }
        }
      }
    }
  }

  .ui.container.class-detail .ui.segment.list-card .card-nav-buttons {
    .button, button {
      // min-height: var(--theme-primary-button-min-height) !important;
      // padding-bottom: 1px !important;
      box-shadow: none !important;
      font-size: var(--theme-secondary-font-size) !important;
      min-width: 88px !important;
      padding: 0.6em 1.7em !important
    }
  }

  .ui.fluid.container.assignment-view-container {
    .subHeaderWrapper {
      button[menustatus] {
        color: var(--theme-secondary-top-nav-color) !important;
        font-size: var(--theme-font-size) !important;
        font-weight: bold !important;
        padding-top: 12px !important;
        &.active {
          // TODO remove // background-color: var(--theme-primary-button-bg-color) !important;
          // background-color: var(--theme-secondary-nav-button-bg-color !important;
          background-color: var(--assignment-active-tab-nav-background-color) !important;
          // color: var(--theme-secondary-top-nav-selected-color) !important;
          color: var(--assignment-active-tab-nav-color) !important;
          &:hover {
            background-color: var(--assignment-active-tab-nav-hover-color) !important;
          }
        }
        &:not(.active, [menustatus='all']) {
          &:hover {
            background-color: var(--assignment-tab-nav-hover-color) !important
          }
        }
      }
      button[menustatus='all'] {
        // placeholder
        // color: var(--theme-font-color) !important;
      }
      .teacher-assignments-menu-control {
        .teacher-menu {
          button {
            font-weight: bold !important;
            padding-top: 1px !important
            // padding-top: 3px !important;
          }
        }
      }
    }
  }

  .btns-summary-and-details-wrapper {
    .button {
      font-size: var(--theme-font-size) !important;
    }
  }
  .gradebook-header-right {
    .top-button-wrapper, .bottom-button-wrapper {
      div:not(.btn-export-wrapper) {
        .button {
          background-color: var(--theme-primary-banner-button-bg-color) !important;
          color: var(--theme-primary-banner-button-color) !important;
          font-size: var(--theme-font-size) !important;
          font-weight: 500 !important;
          &:hover {
            background-color: var(--theme-primary-banner-button-hover-color) !important;
          }
          &.disabled {
            // placeholder
          }
        }
      }
      .btn-export-wrapper {
        .btn-export {
          &.button {
            background-color: var(--gradebook-export-button-bg-color) !important;
            border-color: var(--gradebook-export-button-border-color) !important;
            color: var(--gradebook-export-button-color) !important;
            font-weight: 500 !important;
            &:hover {
              background-color: var(--gradebook-export-button-bg-color-hover) !important;
            }
          }
        }
      }
    }
  }
  
  .ui.button.basic.primary.submit-button {
    padding: 0;
  }
  .ui.mini.button.password-display-btn {
    // border: 2px solid var(--theme-border-color) !important;
    // color: #8d8d8d !important;
    border-radius: var(--theme-button-border-radius) !important;
    border: 2px solid var(--theme-basic-button-color) !important;
    color: var(--theme-basic-button-color) !important;
    font-weight: bold;
    margin-left: -106px !important;
    width: 100px;
  }
  .class-header .ui.button.primary.add-course-button {
    font-weight: bold;
    font-size: 13px;
  }
  .ui.segment.item.student-assignment-card .ui.primary.button {
    font-size: var(--theme-secondary-font-size) !important;
    height: auto;
    width: auto;
  }
  .ui.segment.leaf-card-satcore.list-card, .ui.segment.branch-card-satcore.list-card {
    padding: 0 !important;
  }
  .student-client {
    .ui.segment.leaf-card-satcore.list-card, .ui.segment.branch-card-satcore.list-card {
      .card-nav-buttons .ui.button {
        font-size: var(--theme-secondary-font-size) !important;
        height: auto;
        width: 80px;
      }
    }
    .ui.segment.course-card-satcore.clickable.list-card .card-nav-buttons button.course-view-button {
      font-size: var(--theme-secondary-font-size) !important;
      height: auto;
      width: 80px;
    }
    .ui.segment.leaf-card-satcore.list-card .card-nav-buttons .ui.button.leaf-button,
    .ui.segment.branch-card-satcore.list-card .card-nav-buttons .ui.button.leaf-button {
      font-size: var(--theme-secondary-font-size) !important;
    }

    .ui.container.class-detail .ui.segment.list-card .card-nav-buttons button {
      padding-top: 0px !important;
      padding-bottom: 1px !important;
    }
  }
  /* Modal */
  .ui.modal {
    &.fullscreen {
      .f-header {
        .modal-actions {
          .ui.basic.button {
            // TODO remove // color: black !important;
            background: white !important;
            color: var(--theme-basic-button-color) !important;
          }
        }
      }
    }
    .content {
      .assignment-banner {
        padding: 5px 0;
        .title-wrapper {
          margin-left: 16px;
        }
        .flex-subheader {
          color: #848484;
          font-size: 13px;
        }
      }
      .assignment-wrapper-modal {
        .ui.basic.button {
          color: var(--theme-primary-button-bg-color) !important;
        }
      }
    }
  }

  .individual-student-performance .grid-column-header {
    padding: 0.92857143em 0.78571429em;
    font-size: 12px;
  }
  .ui.segment.item.assignment-card {
    padding: 5px;
  }

  .ui.modal.l-scoring-modal {
    .l-scoring-modal-container {
      .l-scoring-modal-upper-header {
        background: var(--theme-secondary-banner-color);
      }
    }
  }

  .l-player-modal {
    .l-header {
      background: var(--theme-secondary-banner-color);
    }
    .student-divider {
      display: none;
    }
    .ui.primary.button.student {
      display: none;
    }
  }
  .student-client .ui.container.dashboard-container .widget-wrapper .dashboard-widget.progress {
    visibility: hidden;
}
}
