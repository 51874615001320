body.c2c-satellite.openupresources .assignment-view-container {
  .filter-wrapper {
    .sc-radio .radio-label {
      font-size: var(--filter-card-label-font-size) !important;
    }
    .check-label {
      font-size: var(--filter-card-label-font-size) !important;
    }
  }
  .subHeaderWrapper {
    color: rgba(255, 255, 255, 0.6) !important;
    .teacher-assignments-menu-control {
      .teacher-menu {
        .button {
          font-size: var(--theme-font-size);
        }
      }
    }
  }
  .filter-content-type-section {
    display: none;
  }
  .ui.table {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border: none;
  }
  .ui.segment.item.assignment-card {
    padding: 0px !important;
    .flex-grid.top {
      margin-bottom: 3px;
    }
    .content {
      padding: 10px 20px 0px 10px;
    }
    .row {
      .subtitle {
        font-weight: normal;
        .expander {
          // color: #848484;
          color: black;
          font-size: var(--theme-font-size);
          margin-bottom: 8px;
          margin-top: 2px;
        }
      }
      .classroomName {
        font-weight: 600;
      }
    }
    .card-value {
      font-size: 13px;
      font-weight: normal;
    }
  }
  .filter {
    .ui.grid {
      margin-right: 0;
      margin-top: 0;
    }
    .ui.table {
      td.active {
        background-color: #3a9cfa !important;
        outline: none;
      }
    }
  }
}
