body.c2c-satellite.openupresources .page .ui.fullscreen.modal.full-screen-modal-page.profile-page {
  &.modal-student-client {
    background-color: var(--theme-background-color) !important;
    background-image: url('../img/openupresources-bg-student.webp') !important;  
    background-size: cover !important;
    --theme-font-color-directly-on-background: white;
  }
  &:not(.modal-student-client) {
    background-color: var(--theme-background-color) !important;
    background-image: url('../img/openupresources-bg.webp') !important;  
    background-size: cover !important;
  }
  
  .profile-txt {
    /* placeholder */
  }
  .profile-fields .profile-field-txt {
    /* placeholder */
  }

  .ui.mini.button.avatar-button {
    /* placeholder */
  }
  .ui.basic.primary.button.profile-change-password-btn {
    /* placeholder */
  }
}
