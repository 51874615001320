body.c2c-satellite.openupresources {
  .AdminUsersView.usersView {
    .tableContainerHeader {
      .tableRightHeader {
        .add-new-teacher-button-container {
          .primary.button {
            background-color: var(--theme-primary-banner-button-bg-color) !important;
            border-radius: var(--theme-secondary-button-border-radius) !important;
            color: var(--theme-primary-banner-button-color) !important;
            font-size: var(--theme-font-size) !important;
            font-weight: 500 !important;
            &:hover {
              background-color: var(--theme-primary-banner-button-hover-color) !important;
            }
          }
        }
      }
    }
  }
}
