body.c2c-satellite.openupresources {
  .ui.fluid.container.student-assignment-view-container {
    .no-assignments-wrapper {
      .no-assignments-message {
        color: var(--theme-font-color-directly-on-background);
      }
    }
    .sa-header-row-img {
      margin-top: 30px;
      background: none;
      color: inherit;
      .sa-header-label {
        color: var(--theme-font-color-directly-on-background) !important;
        font-family: var(--theme-font-family) !important;
      }
    }
    .ui.menu, .student-menu {
      background-color: var(--theme-banner-color);
      border-radius: 30px !important;
      border: 1px solid #cfcfcf !important;
      border: none;
      box-shadow: none;
      display: flex;
      font-size: 15px;
      font-weight: 600;
      min-height: fit-content !important;
      .item {
        cursor: pointer;
        background-color: var(--theme-background-header-color);
        border: none;
        color: var(--theme-secondary-nav-button-color) !important;
        font-weight: 600;
        justify-content: center;
        min-width: 150px;
        padding-top: 20px;
        div {
          border-bottom: 4px solid transparent;
          font-weight: bold !important;
        }
        &.active {
          background: var(--theme-secondary-nav-button-bg-color) !important;
          color: var(--theme-secondary-nav-button-color) !important;
          box-shadow: none;
          div {
            padding: 5px;
          }
        }
        &:not(:last-child) {
          border-right: 1px solid #cfcfcf !important;
        }
      }
    }
    .sa-second-header-row {
      .sa-search-n-sort {
        .button {
          font-size: var(--theme-font-size) !important;
        }
        .ui.input.search-input {
          font-size: 14px !important;
        }
      }
    }
  }
  .today-is {
    color: #2a2b2d !important;
  }
  .sa-today-element-img {
    background-image: url('../img/student-today-is.svg');
    color: #2a2b2d !important;
  }
}
