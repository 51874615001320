body.c2c-satellite.openupresources .class-settings-view {
  .class-header {
    h2 {
      color: var(--theme-font-color-directly-on-background);
      margin: auto;
      width: 725px;
    }
    background: inherit !important;
  }
  .ui.fluid.container.bread-crumb-wrapper.bottom {
    display: none;
  }

  .class-settings {
    padding-top: 0px !important;
  }

  .ui.container.settings-form .settings-grid .settings-save-button {
    width: 90px;
    float: left;
    margin-top: 20px;
  }
  .ui.container.settings-image {
    height: max-content;
  }
  .ui.container.settings-form {
    background: inherit;
    background-size: cover;
    .settings-grid {
      h2, h3 {
        // placeholder
      }
      label {
        font-weight: normal !important;
      }
      .column h2.ui.header {
        display: none;
      }
    }
  }
  .studentAccessCode, .coTeacherAccessCode {
    background: inherit;
    .accessCodeHeader {
      font-weight: normal !important;
    }
  }
}
