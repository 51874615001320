body.c2c-satellite.openupresources .verification-page {
  height: 100vh;
  .verification-header {
    background-color: var(--theme-tertiary-banner-color) !important;
    .header-text {
      color: var(--theme-header-color-login) !important;
      font-weight: bold;
    }
  }
  .panel-body {
    margin: auto !important;
  }
  .element-body-verification {
    border: 1px solid #666666 !important;
  }
}
