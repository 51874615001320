body.c2c-satellite.openupresources .class-roster-view {
  .ui.container.roster-tab-container {
    .tab-button {
      border: solid 1px #dedfdf;
      background-color: var(--theme-background-color);
    }
    .active {
      background-color: var(--theme-dark-border-color);
    }
  }

  .classRosterTable {
    .table-header-row {
      padding: var(--roster-table-row-padding) !important;
      .table-header-cell {
        &:not(.table-header-cell-medium, .table-header-cell-small) {
          width: var(--roster-table-cell-default-width) !important;
        }
        &.table-header-cell-medium {
          width: var(--roster-table-cell-medium-width) !important;
        }
        &.table-header-cell-small {
          width: var(--roster-table-cell-small-width) !important;
        }
      }
    }
    .table-row {
      padding: var(--roster-table-row-padding) !important;
      .table-cell {
        &:not(.table-cell-medium, .table-cell-small) {
          width: var(--roster-table-cell-default-width) !important;
        }
        &.table-cell-medium {
          width: var(--roster-table-cell-medium-width) !important;
        }
        &.table-cell-small {
          width: var(--roster-table-cell-small-width) !important;
        }
        .ui.button {
          font-size: var(--roster-table-cell-button-default-font-size) !important;
        }
      }
    }
  }
}
