body.c2c-satellite.openupresources .ui.container.group-roster-view {
  .groupRosterTable {
    .tableHeaderContainer {
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
      padding: 20px !important;
    }
    .tableContainer {
      .filteredHeaderTableContainer {
        .filteredHeaderTable {
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
        }
      }
    }
  }
}
