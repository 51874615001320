body.c2c-satellite.openupresources .ui.fluid.container.grade-book-view {
  .ui.breadcrumb, .ui.large.breadcrumb {
    background-color: var(--breadcrumb-wrapper-color) !important;
    // margin-top: 20px;
    // margin-bottom: 0;
    margin: 15px auto 0 auto !important;
    padding-bottom: 0 !important;
  }
  .ui.segment.vertical.gradebook-card {
    .image {
      border-radius: var(--student-border-radius) 0 0 var(--student-border-radius);
    }

    .gradebook-card-content {
      .ui.basic.primary.button {
        box-shadow: none !important;
      }

      .blue {
        font-family: inherit;
      }

      .assignment-status-control {
        .assignment-status-list {
          >li:after {
            margin-left: 10.5px;
            top: -23%; // fixes the line between option bullets due to font differences.
          }

          >li:before {
            font-size: 40px;
          }
        }
      }
    }
  }

  .gradebook-container {
    .gradebook-breadcrumbs-wrapper {
      // TODO remove // margin-top: 1px;
    }

    .btn-aggregate-gradebook {
      width: 300px;
      border-radius: 0;
      color: white;
      font-weight: 600;
      background-color: var(--theme-primary-button-bg-color);
    }

    .ui.segment.vertical.gradebook-card {
      border-radius: var(--student-border-radius);
      box-shadow: none;
      border-color: var(--card-border-color);
    }

    .btns-summary-and-details-wrapper {
      min-height: 33.5px !important;
      .ui.button {
        padding-bottom: 4px !important;
        &.primary {
          background-color: var(--assignment-active-tab-nav-background-color) !important;
          color: var(--assignment-active-tab-nav-color) !important;
          &:hover {
            background-color: var(--assignment-active-tab-nav-hover-color) !important;
          }
        }
        &:not(.primary) {
          // border: 1px solid #dedfdf;
          background: var(--assignment-tab-nav-background-color) !important;
          color: var(--assignment-tab-nav-color) !important;
          &:not(:hover) {
            background-color: var(--assignment-tab-nav-hover-color) !important;
          }
        }
      }

      .button {
        &.primary {
          border: 1px solid #dedfdf !important;
          &:hover {
            border: 1px solid #dedfdf !important;
          }
        }
      }
    }
  }

  .grade-book-layout {
    .gradebook-header-container {
      .gradebook-header-left {
        h3.header {
          color: var(--theme-primary-button-color);
        }
      }
      .gradebook-header-right {
        .gradebook-colorkeycard-outer-wrapper {
          .color-key-card-container {
            .color-keys .color-key {
              .label-wrapper .bold-label {
                font-weight: normal !important;
                font-size: var(--gradebook-detail-colorkey-font-size) !important;
              }
            }
          }
        }
      }

      .btn-export {
        padding: 0px;
      }
    }

    .gradebook-data-content {
      border: solid 1px var(--card-border-color);
      background-color: var(--card-content-background-color);
      border-radius: var(--student-border-radius);
      padding: 17px 28px;
      justify-content: space-between;

      .fg-col {
        .card-value.grade-low,
        .card-value.grade-med,
        .card-value.grade-high {
          color: inherit;
        }
      }

      .fg-col.progress .fg-row {
        align-items: baseline;

        .progress-bar {
          border-radius: var(--student-border-radius);
          background-color: #767676;
          height: 10px;
          width: 120px;
          .grade-high,
          .grade-med,
          .grade-low {
            height: inherit;
            background-color: var(--theme-secondary-font-color);
          }
        }
      }
    }

    tbody tr td.cell-submitted .ui.primary.button {
      font-size: 10px;
      font-weight: normal !important;
      padding: 0px;
      border-radius: var(--student-border-radius) !important;
    }
  }

  table {
    tr {
      th, td {
        font-size: var(--theme-font-size) !important;
      }
    }
  }
}
