/* any satellite-specific report style overrides can go into this file */
body.c2c-satellite.openupresources {
  .report-header {
    .report-header-left {
      .report-context-switcher {
        .context-switcher-button {
          .ui.button {
            font-weight: bold !important;
            &.primary {
              background-color: var(--assignment-active-tab-nav-background-color) !important;
              color: var(--assignment-active-tab-nav-color) !important;
            }
            &:not(.primary) {
              background-color: var(--assignment-tab-nav-hover-color) !important;
              color: var(--assignment-tab-nav-color) !important;
            }
          }
          .ui.button:not(.primary) {
            // placeholder
            // TODO remove
            // background-color: var(--theme-secondary-button-bg-color);
            // color: var(--theme-secondary-button-color);
          }
        }
      }
      .report-dropdowns {
        .report-whitelabel-dropdowns {
          .report-group-dropdown {
            .dropdown-label {
              padding-top: 3px !important;
            }
          }
        }
      }
    }
    .report-header-right {
      .report-header-right-top {
        .report-download-buttons {
          .report-export, .report-print {
            .ui.button {
              background-color: var(--report-download-button-bg-color) !important;
              border: 1px solid var(--report-download-button-border-color) !important;
              color: var(--report-download-button-color) !important;
              &:hover {
                background-color: var(--report-download-button-bg-color-hover) !important;
              }
            }
          }
        }
      }
    }
  }
  
  .report-view {
    .report-table-container {
      .report-table-wrapper {
        margin: 12px 48px 45px;
      }
    }
  }

  .report-type-selector-view .report-selector-cards {
    .report-selector-card {
      .card-top {
        .card-title {
          font-weight: bold !important;
        }
      }
      .card-bottom {
        .report-selector-list {
          .ui.list .item {
            .content > .description > span > .ui.image {
              width: 44px;
            }
            .selector-classroom-name-wrapper {
              min-width: fit-content;
            }
          }
          .button-view-report-wrapper {
            .ui.button.button-view-report {
              height: fit-content !important;
              .button-view-report-title {
                padding-top: 3px;
              }
            }
          }
        }
      }
    }
  }

  .report-color-key-card {
    &:not(.individual) {
      min-width: 454px !important;
      max-width: 454px !important;
    }
  }

  .student-client {
    .my-progress {
      .theme-header-title {
        color: var(--theme-font-color-directly-on-background) !important;
      }
    }
  }
}
