body.c2c-satellite.openupresources
.class-workspace-view {
  .class-workspace-header {
    width: 260px;
  }

  > .ui.container.class-workspace-wrapper{
    max-width: 885px;
    margin: 38px 207px 0 208px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    border-top: solid 13px #f3f3f3;
  }
}
