body.c2c-satellite.openupresources {
  .class-detail {
    .no-courses {
      color: var(--theme-font-color-directly-on-background) !important;
    }

    .list-card {
      .branch-card-image-wrapper {
        margin: var(--course-tree-branch-card-image-wrapper-margin) !important;
        width: 157px !important;
        .branch-card-image:not(.with-image) {
          padding: 27px 40px !important;
        }
      }
      .leaf-card-image-wrapper {
        margin: var(--course-tree-leaf-card-image-wrapper-margin) !important;
        width: 157px !important;
      }
    }
  }

  .student-client {
    .list-card {
      .branch-card-image-wrapper {
        margin: var(--student-course-tree-branch-card-image-wrapper-margin) !important;
      }
      .leaf-card-image-wrapper {
        margin: var(--student-course-tree-branch-card-image-wrapper-margin) !important;
      }
      .card-nav-buttons {
        .button {
          // placeholder
        }
      }
    }
  }

  .course-search-container {
    .leaf-card-satcore {
      &.list-card {
        .card-nav-buttons {
          gap: 3px !important;
        }
      }
    }
  }
}
