body.c2c-satellite.openupresources {
  .ui.button.login, .ui.button.sign-up {
    font-size: 16px;
    height: 41px !important;
    padding: 0 !important;
    width: 87px !important;
  }
  .login-grid {
    &.new-password {
      .column .form .header {
        background-color: var(--theme-tertiary-banner-color) !important;
        .header-text {
          font-weight: bold !important;
          color: var(--theme-header-color-login) !important;
          font-size: 22px !important;
        }
      }
    }
  }
  .ui.grid.login-body {
    margin-bottom: 0 !important;
    &.register-body {
      min-height: 700px !important;
    }
    &.forgot-password-body {
      max-width: unset !important;
      .column {
        max-width: 600px !important;
        .ui.message {
          .header {
            background-color: transparent !important;
          }
        }      
      }
    }
  
    .login-center-logo {
      background-image: url('../img/openupresources-logo-light.svg');
      background-repeat: no-repeat;
      display: block;
      height: 70px;
      margin: 0 auto 37px auto;
      width: 532px;
    }
    .header {
      // height: 50px !important;
      background-color: var(--theme-tertiary-banner-color) !important;
      .header-text {
        color: var(--theme-header-color-login) !important;
        font-size: var(--theme-header-font-size-login) !important;
        font-weight: bold !important;
        height: fit-content !important;
      }
    }   
    .element-body-login {
      box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px #fbfbfc;
      padding: 22px 66px !important;

      &:not(.element-body-login-register) {
        padding-bottom: 8px !important;
      }
      
      .field {
        .login-password {
          height: 38.47px !important;
          input[type="password"] {
            // TODO remove // font-size: 15px !important;
            // padding-bottom: 5px !important;
          }
          .password-display-btn {
            height: 27.5px !important;
          }
        }
        .note {
          font-weight: 400 !important;
        }
        > label {
          font-weight: normal;
          color: var(--theme-font-color);
        }
        input {
          line-height: 1.45;
        }
        .forgot-link {
          color: var(--theme-link-color) !important;
          font-size: var(--theme-font-size) !important;
          font-weight: normal !important;
        }

        .ui.segment.button-list {
          display: flex;
          justify-content: center !important;
          width: fit-content !important;
          div {
            white-space: nowrap;
            &:not(:last-child) {
              margin-right: 20px;
            }
          }
          margin: auto;
          border: 0;
          .ui.button {
            border-radius: var(--theme-secondary-button-border-radius) !important;
            line-height: normal;
            padding-top: 1px !important;
            &:not(.basic) {
              background-color: var(--theme-basic-button-color) !important;
              &:hover {
                background-color: var(--theme-basic-button-hover-color) !important;
              }
            }
            &.basic {
              color: var(--theme-basic-button-color) !important;
              border: 2px solid var(--theme-basic-button-color) !important;
              &:hover {
                color: var(--theme-basic-button-hover-color) !important;
                border: 2px solid var(--theme-basic-button-color) !important;
              }
            }
          }
        }
      
        .ui.segment.single-button {
          font-family: var(--theme-font-family);
          width: 100%;
          margin: auto;
          border: 0;
          box-shadow: none;
          background-color: var(--theme-element-color-login);
          .ui.button {
            font-family: var(--theme-font-family);
            font-size: 16px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: center;
            color: #fbfbfc;
          }
        }
      
        .ui.segment.sso-button-list {
          width: 246px;
          margin: auto;
          padding: 20px 0 20px 0;
          border: 0;
          border-top: 1px solid var(--theme-dark-border-color);
          box-shadow: none;
          display: flex;
          flex-direction: column;
          background-color: var(--theme-element-color-login);
          .ui.button {
            padding: 2px 0;
            [class*='label'] {
              color: var(--theme-secondary-button-color);
              white-space: nowrap;
            }
          }
        }
      }
      .ui.message {
        .header {
          background-color: transparent !important;
        }
      }
    }
  }

  .ui.header > .ui.image.logo {
    display: none;
  }
  .form .ui.attached.block.header {
    border: none;
  }
  .message-wrapper {
    margin-bottom: 21px;
    .signin-message {
      display: flex;
      font-size: 16px !important;
      justify-content: center;
      img {
        margin-right: 5px;
      }
      a {
        // color: var(--theme-secondary-font-color);
        color: var(--theme-link-color);
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
      }
    }
  }
}
