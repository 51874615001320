body.c2c-satellite.openupresources {
  .ui.segment.class-card-satcore {
    &.list-card {
      border: 1px solid var(--card-border-color) !important;
    }
    .card-body {
      .card-info {
        .class-card-header {
          .classroom-title-wrapper {
            .classroom-title {
              // placeholder
              // TODO remove // font-size: 18px !important;
            }
          }
        }
      }
    }
  }
}
