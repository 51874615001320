body.c2c-satellite.openupresources {
  .student-client .ui.container.dashboard-container {
    .headerContainer {
      margin-top: -15px !important;
      margin-bottom: 15px !important;
      .headerWrapper {
        .welcome-header {
          font-size: 30px;
          font-weight: bold !important;
          margin-bottom: 20px;
        }
        .welcome-date {
          color: var(--theme-font-color);
          font-weight: 300 !important;
          font-size: 18px !important;
        }
      }
    }

    .widget-wrapper {
      width: fit-content;
      .dashboard-widget {
        height: auto !important;
      }
      .left-col {
        // placeholder
      }
      .dashboard-widget.green .w-content .ui.items.scroller .dash-card.ui.items {
        border-bottom: none;
        .student-assignment-card {
          min-width: 0px !important;
          max-width: 1023px !important;
          min-height: var(--student-dashboard-assignment-card-min-height) !important;
          // padding: var(--student-dashboard-assignment-card-padding) !important;

          .card-grid-container {
            line-height: 1.1 !important;
            margin-top: 6px !important;
          }

          .flex-grid-one {
            padding: 2px 2px 0 2px !important;
          }

          .flex-grid-spaced {
            &.dash-bar, &.sub-meta {
              line-height: 1.5 !important;
              padding-top: 7px !important;
            }
            &.reduce-padding {
              padding-top: 0 !important;
            }
          }

          .content {
            padding-top: 0 !important;
          }

          .fg-column-lg {
            width: 81% !important;
          }

          .card-nav-buttons {
            .button {
              // padding: 7px 8.5px 8.5px 8.5px !important;
              line-height: 0.99 !important;
              min-height: 26px !important;
              padding: 6.5px 10.5px 4.5px !important;
            }
          }
          
          .image img {
            border-radius: var(--student-border-radius) 0px 0px var(--student-border-radius);
          }
          .card-label, .card-value {
            font-size: var(--theme-font-size) !important;
          }
          .due-date {
            .card-label {
              // placeholder
              // TODO remove // padding-left: 15px !important;
            }
          }
        }
      } 
      .right-col {
        min-width: 724px;
        width: 100%;
      }
      .dashboard-widget {
        .w-content, .w-title {
          background-color: #fff;
          font-family: var(--theme-font-family) !important;
          font-size: var(--student-dashboard-widget-top-label-font-size);
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          color: rgba(0, 0, 0, 0.87);
        }
        .w-content {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          min-height: 204px;
        }
        .w-title {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border-top: 13px solid;
        }
        .actions .button {
          font-size: var(--theme-secondary-font-size) !important;
          padding-top: 0.6em;
        }
        &.red {
          .w-content {
            .slick-arrow {
              margin-top: 15px;
              &:before {
                color: #707070;
              }
              &.slick-next {
                border-radius: 0px var(--student-border-radius) var(--student-border-radius) 0px;
              }
              &.slick-prev {
                border-radius: var(--student-border-radius) 0px 0px var(--student-border-radius);
              }
            }
            .course-card-image {
              border-radius: 0px;
            }
            .actions .button {
              margin: 10px auto 14px;
            }
          }
          .w-title {
            border-top-color: var(--theme-secondary-border-color);
          }
        }
        &.green {
          .w-title {
            border-top-color: var(--theme-secondary-border-color);
          }
        }

        &.progress {
          .w-title {
            border-top-color: var(--theme-secondary-border-color) !important;
          }
          .trophy-count {
            display: table  !important;
            font-size: var(--student-dashboard-widget-trophy-banner-font-size) !important;
            font-weight: 500 !important;
            vertical-align: middle !important;
          }
          .trophy-range {
            font-size: var(--student-dashboard-widget-trophy-range-font-size) !important;
            font-weight: normal !important;
          }
          // visibility: hidden;
        }
        
        .book-card-container, &.progress {
          .actions {
            .button {
              // padding: 7px 8.5px 8.5px 8.5px !important;
              width: 105px !important;
            }
          }
        }
      }
    }
  }
}
