body.c2c-satellite.openupresources {
  .bread-crumb-course-dropdown-wrapper {
    &.top {
      height: 0px;
      .bread-crumbs-course-dropdown {
        top: 10px;
      }
    }
    .bread-crumbs-course-dropdown {
      .ui.primary.button {
        &.close-menu, &.open-menu {
          font-weight: normal !important;
          font-size: var(--theme-font-size) !important;
        }
        &.open-menu {
          background: var(--theme-primary-button-bg-color);
          &:hover {
            background: var(--theme-primary-button-hover-color);
          }
        }
        &.close-menu {
          background: var(--theme-primary-button-hover-color);
          &:hover {
            background: var(--theme-primary-button-bg-color);
          }
        }
      }
    }
  }
  .student-client .bread-crumb-course-dropdown-wrapper {
    &.top {
      height: 10px;
    }
  }
}
