body.c2c-satellite.openupresources {
  :not(.dashboard-container) {
    .student-assignment-card {
      max-height: 115px !important;
      .flex-grid-spaced {
        &.dash-bar, &.sub-meta {
          &:not(.reduce-padding) {
            padding-top: 15px !important;
          }
          &.reduce-padding {
            padding-top: 6px !important;
          }
        }
      }
      .content {
        padding-top: 6px !important;
        padding-right: 0px !important;
      }
      .fg-column-lg {
        // placeholder
        // TODO remove, this causes overflow when student assignment cards have long titles
        // width: 110% !important;
      }
    }
  }

  .ui.segment.item.student-assignment-card {
    border: 1px solid #cfcfcf !important;
    border-radius: var(--border-radius) !important;
    min-width: 1023px;
    max-width: 1023px !important;
    min-height: var(--student-assignment-card-min-height) !important;
    // padding: var(--student-assignment-card-padding) !important;
    padding-left: 0;

    .card-grid-container {
      margin-left: -12px !important;
    }

    .student-assignment-card-course-info {
      max-width: 750px;
    }

    .flex-grid-spaced {
      margin-bottom: 0px !important;

      &.dash-bar, &.sub-meta {
        // placeholder
      }
      .fg-column.dash-sub .card-label {
        // TODO remove // color: #777777;
        color: black;
        font-size: 13px;
        font-weight: normal;
      }
    }
    .content {
      padding-left: 0 !important;
      padding-bottom: 0;
    }

    .image {
      img {
        border-radius: var(--student-border-radius) 0 0 var(--student-border-radius) !important;
        height: 105px !important;
        margin: 4px !important;
        width: 148px !important;
      }
      &:not(.default) {
        img {
          padding: 0 !important;
        }
      }
      &.default {
        width: 175px !important;
        img {
          padding: 30px !important;
        }
      }
      
    }

    .fg-column {
      .card-value {
        font-size: var(--theme-font-size) !important;
        padding-right: 24px !important;
      }
    }

    .card-nav-buttons {
      .ui.button {
        // border-radius: 0 !important;
        // width: 67px;
        padding: 6.5px 10.5px 4.5px !important;
        min-width: 79.46px !important;
        &:not(.disabled, .pseudo-disabled) {
          // placeholder
          // background-color: var(--student-assignment-card-button-bg-color) !important;
        }
        // TODO remove
        // &:first-child:nth-last-child(n + 2) {
        //   padding: 0.6em 1.7em;
        //   & ~ .ui.ui.button {
        //     padding: 0.433em 0.9em;
        //   }
        // }
      }
    }
  }
}
