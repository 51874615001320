body.c2c-satellite.openupresources {
  .ui.button, .ui.basic.button, .ui.primary.button {
    padding: 0.6em 1.7em;
    font-family: var(--theme-font-family);
    font-weight: bold;
    min-height: var(--theme-primary-button-min-height) !important;
  }
  .ui.primary.button {
    // border-radius: var(--theme-button-border-radius) !important;
    // font-weight: 600 !important;
    background-color: var(--theme-primary-button-bg-color);
    box-shadow: none !important;
    padding: 0.6em 1.7em;
    
    &:focus {
      // color: var(--theme-primary-button-color);
      // background: none var(--theme-primary-button-bg-color);
      text-shadow: none !important;
    }
    &:hover {
      // background: none var(--theme-primary-button-hover-color);
      color: var(--theme-primary-button-color) !important;
      border: 0;
      text-shadow: none !important;
      box-shadow: none !important;
    }
    &:not(.basic) {
      &:hover {
        background-color: var(--theme-primary-button-hover-color) !important;
      }
    }
    &.basic {
      color: var(--theme-basic-button-color) !important;
      box-shadow: none !important;
      &:hover {
        border: solid 2px var(--theme-basic-button-hover-color) !important;
        color: var(--theme-basic-button-hover-color) !important;
      }
    }
  }

  .modal-title-button {
    display: flex;
    .button {
      min-width: fit-content !important;
    }
  }
  // TODO remove
  // .modal-title-button .ui.primary.button {
  //   background-color: #fff;
  //   color: var(--theme-top-nav-color);
  //   box-shadow: none !important;
  //   border: none;
  //   &:focus {
  //     background: none var(--theme-basic-button-color);
  //     color: var(--theme-primary-button-color);
  //     text-shadow: none !important;
  //   }
  //   &:hover {
  //     background: none var(--theme-basic-button-color);
  //     color: var(--theme-primary-button-color) !important;
  //     border: 0;
  //     text-shadow: none !important;
  //     box-shadow: none !important;
  //   }
  //   &.white {
  //     &.primary {
  //       background-color: var(--theme-basic-button-color) !important;
  //       color:  #fff !important;
  //       &:hover {
  //         background: none;
  //         background-color: var(--theme-primary-button-color) !important;
  //         color: var(--theme-basic-button-color) !important;
  //         border: 0;
  //         text-shadow: none !important;
  //         box-shadow: none !important;
  //       }
  //     }
  //   }
  // }

  .ui.disabled.button {
    background-color: var(--theme-primary-button-bg-disabled-color);
    color: #767676;
  }
  .ui.mini.button {
    border: solid 2px var(--theme-primary-button-bg-disabled-color) !important;
    font-weight: bold;
    padding: 0.6em 1.7em;
  }
  .ui.basic.button/*:not(.white)*/ {
    &.primary {
      background-color: var(--theme-primary-button-color) !important;
    }
    border: solid 2px var(--theme-basic-button-color);
    box-shadow: none !important;
    color: var(--theme-basic-button-color) !important;
    font-weight: bold;
    &:hover {
      border: solid 2px var(--theme-basic-button-hover-color);
      box-shadow: none !important;
      color: var(--theme-basic-button-hover-color) !important;
    }
    &.white {
      &:hover {
        background-color: #fff !important;
      }
    }
  }
  .ui.basic.primary.button, .ui.basic.primary.button:hover {
    box-shadow: none !important;
  }
  .ui.tiny.button {
    border-radius: var(--theme-button-border-radius);
    line-height: 1.15;
    padding: 0.6em 1.7em;
  }
  :not(.student-client) {
    .ui.button, .ui.basic.button, .ui.primary.button {
      border-radius: var(--theme-button-border-radius) !important;
    }
  }

  // begin STUDENT button styles
  .student-client {
    .ui.button, .ui.basic.button, .ui.primary.button {
      border-radius: var(--theme-secondary-button-border-radius) !important;
      min-height: var(--student-primary-button-min-height) !important;
    }
    // TODO remove
    // .ui.primary.button {
    //   &:not(.disabled) {
    //     background-color: var(--theme-secondary-button-bg-color) !important;
    //     &:hover {
    //       background-color: var(--theme-secondary-button-hover-color) !important;
    //     }
    //   }
    // }
  }
  .popup-button-student {
    &.ui.button, &.ui.basic.button, &.ui.primary.button {
      border-radius: var(--theme-secondary-button-border-radius) !important;
    }
    // TODO remove
    // &.ui.primary.button {
    //   &:not(.disabled) {
    //     background-color: var(--theme-secondary-button-bg-color) !important;
    //     &:hover {
    //       background-color: var(--theme-secondary-button-hover-color) !important;
    //     }
    //   }
    // }
  }
  // end STUDENT button styles

  // button bar
  .ui.button, .ui.basic.button, .ui.primary.button {
    &.bar-btn-left {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &.bar-btn-middle {
      border-radius: 0 !important;
    }
    &.bar-btn-right {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

// TODO phase out, deprecated
.button-basic(@color, @hover) { // TODO phase out (.button-basic()), deprecated
  &.buttons .button, &.button {
    background: transparent !important;
    border: solid 2px @color;
    color: @color !important;
    &:hover, &:focus, &.active {
      color: @hover !important;
    }
  }
}
.button-primary-basic() { // TODO phase out (.button-primary-basic()), deprecated
  .button-basic(var(--theme-basic-button-color), var(--theme-basic-button-hover-color));
}

.button-directly-on-background {
  background-color: var(--theme-primary-banner-button-bg-color) !important;
  color: var(--theme-primary-banner-button-color) !important;
}
