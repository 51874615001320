body.c2c-satellite.openupresources .content-area .dashboard-container {
  .welcome-header {
    color: var(--theme-font-color-directly-on-background) !important;
    font-weight: bold !important;
    .mobile-only {
      display: none !important;
    }
  }
  .welcome-date {
    color: var(--theme-font-color-directly-on-background) !important;
    font-family: var(--theme-font-family) !important;
    font-size: 16px !important;
    font-weight: normal !important;
    letter-spacing: 0.32px !important;
  }

  .headerContainer {
    .addClassButtonWrapper {
      .addClassButton {
        background-color: var(--theme-primary-banner-button-bg-color) !important;
        border-radius: var(--theme-secondary-button-border-radius) !important;
        color: var(--theme-primary-banner-button-color) !important;
        font-size: var(--theme-font-size) !important;
        font-weight: 500 !important;
        width: 165px !important;
        &:hover {
          background-color: var(--theme-primary-banner-button-hover-color) !important;
        }
      }
    }
  }

  .ui.segment.class-card-satcore {
    padding: 0px !important;
    .card-body .subtitle .leaf-description-text {
      color: #848484;
    }
    .ui.divider {
      // TODO remove // margin-top: 3.5px;
      margin-top: 0 !important;
      width: 100%;
    }
    .card-body {
      padding: 0px !important;
      margin-top: 15px;
      height: fit-content;
      line-height: 1.3;
      width: 100%;
      .card-info {
        padding: 0 15px;
        line-height: 1;
        .class-card-header {
          .classroom-title-wrapper {
            .classroom-title {
              font-size: 18px !important;
            }
          }
        }
        .class-card-info {
          line-height: 1.5;
          margin-bottom: 18px !important;
          .card-label {
            font-size: 14px !important;
            font-weight: normal !important;
            &:not(:first-of-type) {
              // placeholder
              // TODO remove // margin-left: 10px !important;
            }
          }
          .card-list-text {
            font-size: var(--theme-font-size) !important;
            &.blue {
              color: var(--theme-link-color) !important;
            }
          }
        }
      }
      .card-nav-buttons {
        padding-left: 15px;
        .button {
          .card-list-text {
            font-size: var(--theme-font-size) !important;
            margin: 4px 15px 0 0 !important;
            &.blue {
              color: var(--theme-link-color) !important;
            }
          }
        }
      }
    }
    .class-card-image-wrapper {
      padding: 10px; // TODO rm if satellite dashboard card should not have img margin
      // margin: 0;
      .class-card-image {
        &.default-image {
          background: var(--card-background-color);
          padding: 27px 39px !important; // TODO rm if satellite dashboard card should not have img margin
        }
        border-radius: 0px;
        // width: 154px;
        width: 143px; // TODO rm if satellite dashboard card should not have img margin
        height: 107px !important; // TODO rm if satellite dashboard card should not have img margin
        .ui.image.class-image {
          border-radius: 0;
          height: 107px !important; // TODO rm if satellite dashboard card should not have img margin
        }
      }
    }
  }
  &.teacher-dashboard-container {
    .headerContainer {
      margin-top: -15px !important;
      margin-bottom: 15px !important;
    }
  }
}
