body.c2c-satellite.openupresources {
  .ui.inverted.menu.topNav {
    background-color: var(--theme-banner-color);
    .header.item {
      .ui.image.logo {
        width: 150px !important;
      }
    }

    .nav-list {
      // placeholder
    }

    .item.tnav-tab {
      color: var(--theme-top-nav-color);
      font-size: 18px;
      font-weight: var(--theme-top-nav-font-weight) !important;
      height: var(--nav-height);
      text-align: center;
      text-transform: capitalize !important;
      &.selected {
        background-color: var(--theme-primary-nav-button-bg-color);
        color: var(--theme-top-nav-selected-color);
      }
      &.disabled {
        &, &:hover {
          color: rgba(40, 40, 40, 0.3) !important;
        }
      }
      &.resources {
        .ui.image {
          display: none !important;
        }
      }
    }
  }
  .nav-separator {
    display: unset !important;
    position: fixed;
    z-index: 100;
    width: 100%;
    height: var(--nav-separator-height);
    background-color: var(--theme-secondary-border-color);
  }
  .ui.avatar.images .image, .ui.avatar.images img, .ui.avatar.images svg, .ui.avatar.image img, .ui.avatar.image svg, .ui.avatar.image {
    width: 36px;
    height: 36px;
  }
  .main-view .ui.inverted.menu.topNav {
    .class-selector {
      .class-dropdown-selection {
        margin-left: 0px;
      }
      .item {
        padding: 0.58347222em 1.14285714em !important;
        &.switch-option {
          background-color: #dadadb !important;
          .join-class {
            padding: 0.6em 1.2em;
            width: 94px;
            min-width: fit-content;
          }
        }
        &:not(:first-child):not(:last-child) {
          border-bottom: 1px solid #d4d4d5;
        }
      }
    }
  }
  .student-client {
    .ui.inverted.menu.topNav {
      background-color: var(--theme-secondary-banner-color);
      box-shadow: var(--theme-secondary-top-nav-box-shadow);
      .item.tnav-tab {
        font-size: 14px !important;
        font-weight: 800 !important;
        text-transform: uppercase !important;
        &.class-menu {
          .class-selector {
            .class-dropdown-selection {
              font-size: var(--theme-tertiary-font-size) !important;
            }
          }
          padding-top: 23px !important;
        }
        &:not(.selected) {
          color: var(--theme-secondary-top-nav-color);
        }
        &.selected {
          background-color: var(--theme-secondary-nav-button-bg-color);
          color: var(--theme-secondary-top-nav-selected-color);
        }
      }
    }
  }
}
