.ui.fullscreen.modal.AddCourseToClassModal {
  .ui.tabular.menu {
    display: none;
  }
  
  .tab-pane-publisher-courses {
    background-color: var(--theme-background-color) !important;
    background-image: url(../img/openupresources-bg.webp) !important;
    .content {
      .class-course-container {
        .class-course-list-container {
          padding: 40px 90px !important;
        }
      }
    }
  }
  .sc-check {
    .check-label {
      .checkmark {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
        border: 1px solid #707070 !important;
        &::after {
          padding: 3px 0 3px 0 !important;
        }
      }
      overflow: inherit !important;
    }
  }
  .course-card-satcore {
    &.course-card-satcore-disabled {
      // placeholder
    }
  }
}
