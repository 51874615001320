// Modals.less - intended for general CSS declarations for modals of a given satellite
// custom modals with large changes (such as AddCourseModal.less) are preferred to have their own CSS file
body.c2c-satellite.openupresources {
  .ui.modal, .AddAssignmentModal, .EditAssignmentModal, .EditBulkAssignmentModal {
    --theme-background-color: var(--theme-secondary-background-color);
    &.StudentAssignmentModal {
      min-width: fit-content !important;
    }

    .modal-header, .actions {
      background: var(--theme-banner-color) !important;
    }

    .modal-header-bar {
      .modal-header-label {
        color: var(--theme-tertiary-font-color) !important;
      }

      .modal-banner-title {
        color: var(--theme-tertiary-font-color) !important;
      }

      .button.btn-modal-banner-close {
        color: var(--theme-tertiary-font-color) !important;
        &:hover {
          color: var(--theme-tertiary-font-color) !important;
        }
      }
    }
    
    &.student-client {
      &.join-class-modal {
        .actions {
          .button {
            font-size: var(--theme-tertiary-font-size) !important;
          }
        }
      }
    }
  }
  
  .full-screen-modal-page {
    &.modal-student-client {
      background-color: var(--theme-background-color) !important;
      background-image: url('../img/openupresources-bg-student.webp') !important;
      background-size: cover !important;
    }
    &:not(.modal-student-client) {
      background-color: var(--theme-background-color) !important;
      background-image: url('../img/openupresources-bg.webp') !important;  
      background-size: cover !important;
    } 
  }

  .ui.fullscreen.modal .modal-title-text {
    line-height: 1.5 !important;
  }

  .modal-title-text, .ui.modal .modal-title-text {
    font-family: var(--theme-font-family) !important;
  }
}
