body.c2c-satellite.openupresources {
  .ui.container.progress-view {
    .class-header {
      h2 {
        padding-left: 14px;
        font-size: 28px;
      }
    }
    .widget-wrapper {
      min-width: 1260px;
      justify-content: center;
      .widget {
        .w-title {
          font-family: var(--theme-font-family);
        }
      }
      .left-col {
        margin-right: 20px;
        .summary-widget {
          margin-bottom: 20px;
        }
        .summary-widget {
          width: 330px;
          height: 200px;
          margin-bottom: 20px;
          .no-scores {
            line-height: 110px;
            height: 110px;
            text-align: center;
          }
          .summary {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            .legend-container {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: space-between;
              padding: 6px 0;
              .legend-item {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                font-size: var(--theme-secondary-font-size);
                font-weight: bold;
                .performance-badge {
                  display: flex;
                  justify-content: center;
                  border-radius: 5px;
                  text-align: center;
                  width: 82px;
                  height: 22px;
                  margin: 0 3px 20px 12px;
                  padding: 2px;
                }
                div {
                  margin-left: 5px;
                  padding-top: 2px;
                }
                .value {
                  width: 20px;
                  margin-left: 3px;
                  text-align: center;
                  &.high {
                    // background-color: #009824;
                  }
                  &.medium {
                    // background-color: #1b8af4;
                  }
                  &.low {
                    // background-color: #d69f00;
                  }
                }
              }
            }
            .d3-pie-chart {
              height: 116px;
              margin: 0 2px 0 7px;
              .pie-chart-tooltip {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                line-height: 1.2;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                border: solid 1px #d4d4d5;
                color: #000;
                background-color: #fff;
                border-radius: 3px;
                padding: 3px;
                font-size: var(--theme-secondary-font-size);
              }
            }
          }
        }
      }
      .right-col {
        max-width: 895px;
        .assignments-widget {
          height: 622px;
          margin-left: 0px;
          .assignments-top-padding {
            height: 13px;
          }
          .ui.table {
            th {
              font-size: var(--theme-tertiary-font-size) !important;
              &.student-progress-achievement {
                font-size: 11px;
              }
            }
            td {
              &.student-progress-content {
                font-size: var(--theme-tertiary-font-size) !important;
              }
              &.student-progress-score {
                font-size: 16px !important;
              }
              &.student-progress-achievement {
                display: var(--student-progress-display-achievement-column);
                font-size: var(--theme-secondary-font-size);
                div {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  div {
                    border-radius: 5px;
                    text-align: center;
                    width: 82px;
                    height: 22px;
                  }
                }
                .high {
                  background-color: #e0efd1;
                }
                .medium {
                  background-color: #fadfaa;
                }
                .low {
                  background-color: #f2c6c7;
                }
              }
            }
          }
        }
      }
    }
  }
}
