body.c2c-satellite.openupresources {
  .libraryView {
    .library-view-container, .builder-view-container {
      .library-view-menu-container, .builder-view-menu-container {
        .button.menu-item {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 1px !important;
          font-size: var(--theme-secondary-font-size) !important;
          &.selected {
            background-color: var(--library-sidenav-button-bg-color-selected) !important;
            color: var(--library-sidenav-button-font-color-selected) !important;
            &:hover {
              background-color: var(--library-sidenav-button-bg-color-selected-hover) !important;
              color: var(--library-sidenav-button-font-color-selected) !important;
            }
          }
        }
      }
      .library-view-content-container, .builder-view-content-container {
        .content-description-text, .null-state-panel, .paginator-container {
          color: var(--theme-font-color-directly-on-background) !important;
          .pagination-text {
            color: var(--theme-font-color-directly-on-background) !important;
          }
        }
      }
    }
  }
}
