body.c2c-satellite.openupresources {
  .course-list-banner-container, .course-list-banner-custom-container {
    .bread-crumb-wrapper {
      background-color: var(--breadcrumb-wrapper-color) !important;
      
      &.top {
        // border-top: 1px solid black;
        display: block;
        margin-top: 0 !important;
      }
      &.bottom {
        display: none;
      }
    }
  }
  .course-list-banner-container {
    .class-header {
      color: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: inherit;
      .banner {
        position: static;
        // TODO remove // max-width: 1127px !important;
        width: 1260px;
        .primary.button {
          background-color: var(--theme-primary-banner-button-bg-color) !important;
          border-radius: var(--theme-secondary-button-border-radius) !important;
          color: var(--theme-primary-banner-button-color) !important;
          font-size: var(--theme-font-size) !important;
          font-weight: 500 !important;
          &:hover {
            background-color: var(--theme-primary-banner-button-hover-color) !important;
          }
        }
      }
    }
    .with-image {
      background-image: none !important;
    }
  }
  .student-client {
    .course-list-banner-container, .course-list-banner-custom-container {
      .bread-crumb-wrapper {
        &.top {
          // placeholder
          // margin-top: 1px !important;
        }
      }
    }
    .class-detail, .class-course-container, .progress-view {
      .class-header.with-image {
        background: none;
      }
    }
  }
}
